<script lang="ts">
import { Component, VModel, Mixins } from "vue-property-decorator";
import { camelCase } from "lodash";
import SelectFieldMixin from "@/mixins/SelectFieldMixin";

@Component({})
export default class CustomerTypeSelect extends Mixins(SelectFieldMixin) {
  @VModel({ type: String }) sType!: string;

  sLabelDefault: string = "customer.type";

  get items() {
    return [
      { value: "customer", text: this.$t("customer") },
      { value: "provider", text: this.$t("provider") },
    ];
  }

  get sRules() {
    return this.required ? "required" : "";
  }

  get sVid() {
    return camelCase(this.label);
  }

  onSelect(sValue: string) {
    this.$emit("input", sValue);
    this.$emit("change", sValue);
  }
}
</script>

<template>
  <ValidationProvider :name="$t(sLabel)" :rules="sRules" :vid="sVid" slim>
    <template #default="{ errors, valid }">
      <v-select
        v-model="sType"
        :error-messages="errors"
        :success="required ? valid : undefined"
        :items="items"
        v-bind="obAttrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>
